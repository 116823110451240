import React from "react";
import SectionHeading from "./sectionHeading";
import { kebabCase } from "helpers";

/* Section
============================================================================= */

const Section = ({ title, children, ariaLabel = "", className }) => {
	// Config

	// Render
	return (
		<section
			role="region"
			aria-label={ariaLabel}
			className={`w-full horizontal-padding padding-top relative ${className}`}
		>
			{title ? <SectionHeading title={title} /> : null}
			{children}
		</section>
	);
};

/* Export
============================================================================= */

export default Section;
