import React from "react";

/* Section Heading
============================================================================= */

const SectionHeading = ({ title }) => {
	// Config

	// Render
	return (
		<h2 className="font-display text-4xl text-black lg:pb-6 pb-4 leading-tight">
			{title}
		</h2>
	);
};

/* Export
============================================================================= */

export default SectionHeading;
