import React from "react";

/* Page Heading
============================================================================= */

const PageHeading = ({ title }) => {
	// Config

	// Render
	return (
		<div className="w-full bg-white pt-20 lg:px-40 px-8 overflow-hidden">
			<h1 className="font-display lg:text-6xl text-5xl text-black leading-tight lg:pt-6 pt-4 lg:pb-5 pb-3">
				{title}
			</h1>
		</div>
	);
};

/* Export
============================================================================= */

export default PageHeading;
