import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { Section } from "components/layout";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Accessibility Page
============================================================================= */

const AccessibilityPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	return (
		<>
			<SEO title="Accessibility" />
			<Section ariaLabel="Accessibility" title="Accessibility">
				<BlockContent
					blocks={data.sanityAccessibility._rawAccessibilityContent}
					className="block-content prose-lg padding-bottom"
					renderContainerOnSingleChild
				/>
			</Section>
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query AccessibilityPageQuery {
		sanityAccessibility {
			_rawAccessibilityContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default AccessibilityPage;
