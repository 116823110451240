import React from "react";
import Section from "./section";

/* List
============================================================================= */

const List = ({ children, title, grid, className, ariaLabel }) => {
	// Config

	// Render
	return (
		<Section title={title} ariaLabel={ariaLabel}>
			<ul
				role="list"
				aria-label={ariaLabel}
				className={`w-full grid ${grid} ${className}`}
			>
				{children}
			</ul>
		</Section>
	);
};

/* Export
============================================================================= */

export default List;
